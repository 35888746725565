import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'
import { auth0Token } from '@/utils/tokenFetch'

import type { ISnapshot } from '@/types/snapshot'

// export interface OrderSnapshot {}
export interface AdvertiserUser {
  id: string
  email: string
  name: string
  status: string
  ref: string
  email_verified: boolean
  provider: string
  user_type: string
  created_at: Date
}

const BaseURL = import.meta.env.VITE_API_URL //"https://test-api.onplad.com/api/onplad"

export const useUserStore = defineStore('user_store', () => {
  const auth0 = useAuth0()

  const snapshot = ref<ISnapshot<AdvertiserUser | undefined>>({
    data: undefined,
    loading: false
  })

  async function fetchUser(): Promise<AdvertiserUser> {
    snapshot.value.loading = true
    try {
      const secureHeaders = new Headers()
      secureHeaders.append('Authorization', 'Bearer ' + (await auth0Token(auth0)))

      const requestUrl = BaseURL + `/v1/advertiser/user`

      const response = await fetch(requestUrl, {
        method: 'GET',
        headers: secureHeaders,
        redirect: 'follow'
      })

      if (response.status !== 200) {
        throw new Error('Unable to get user.')
      }

      const data: AdvertiserUser = await response.json()

      if (!data) {
        throw new Error('Invalid user information.')
      }

      snapshot.value.data = data
      return data

      // eslint-disable-next-line no-useless-catch
    } catch (e: any) {
      throw e
    } finally {
      snapshot.value.loading = false
    }
  }

  const currentUser = computed(() => {
    return snapshot.value.data
  })

  const currentUserId = computed(() => {
    return snapshot.value.data?.id ?? undefined
  })

  return {
    currentUser,
    currentUserId,
    fetchUser
  }
})
