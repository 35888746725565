import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useUserStore } from '@/stores/userStore'
import { useNProgress } from '@vueuse/integrations/useNProgress'

export async function userStatusGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const userStore = useUserStore()
  const { isLoading } = useNProgress()
  try {
    isLoading.value = true
    const user = await userStore.fetchUser()
    next()
  } catch {
    console.log('user', 'invalid-access')
    next('/invalid-access')
  } finally {
    isLoading.value = false
  }
}
