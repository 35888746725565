import type { Auth0VueClient } from '@auth0/auth0-vue'

export async function auth0Token(auth0: Auth0VueClient): Promise<string> {
  return await auth0.getAccessTokenSilently({
    authorizationParams: {
      audience: 'https://dev-gbp1ny1a6f1tevhn.us.auth0.com/api/v2/'
    }
  })
}

export async function secureHeaders(auth0: Auth0VueClient): Promise<Headers> {
  const secureHeaders = new Headers()
  secureHeaders.append('Authorization', 'Bearer ' + (await auth0Token(auth0)))
  return secureHeaders
}

export async function responseHandler<T>(response: Response): Promise<T> {
  if (response.status == 401) {
    throw new Error('User session failed. Please login again')
  }

  if (response.status !== 200) {
    throw new Error('Request failed, please try again.')
  }

  const data: T = await response.json()

  if (!data) {
    throw new Error('Action failed, please try again.')
  }

  return data
}
